<template>
    <div class="nav-tabs-custom position-relativ">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
               <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-user"></i> <span>Agent Details</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-fw fa-ellipsis-h"></i><span>Agent Contact Detail</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab31'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><i class="fa fa-fw fa-reply"></i> <span>Agent Stock Responses</span></a>
            </li>
            <li class="position-absolute agent-name">
               Jhon Doe
            </li>
         </ul>
   
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                <div class="row">
                    <div class="col-md-12 mbottom-15">
                        <div class="row">
                            <div class="col-md-12">
                                <h3></h3>
                            </div>
                           
                            <div class="col-md-3 w-auto">
                                <div class="form-group">
                                    <label class="control-label text-left"> Agent User Type <span class="data-v-tooltip" data-v-tooltip="User Types determine the pages and features available to the agent. Visit the User Types settings page to review the types configured for your tenant." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                    <div tabindex="-1" class="small-input">
                                    <select class="form-control small-input">
                                        <option>Administrator</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="group-inline-item group-bg d-inline-block"><span class="inline-label"><label class="control-label"><i class="fa fa-circle text-danger"></i> Logged In </label></span><span class="inline-label"><label class="control-label"><i class="fa fa-circle text-danger"></i> Chat Available </label></span><span class="inline-label"><label class="control-label">Last Action</label> Invalid date</span><button type="button" class="btn btn-xs btn-primary margin-r-5" disabled="">Logout Agent</button><span class="data-v-tooltip" data-v-tooltip="This section provides the agent's current logged in status and availability for chat. Click the Logout Agent button to log the agent out of the system." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></div>
                            </div>
                            <div class="col-md-12 mbottom-15">
                                <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group"><label class="control-label text-left"> Segment Access <span class="data-v-tooltip" data-v-tooltip="Segment access allows the agent to view any contact that has membership to the segment. It also allows the agent to view all interaction history, contact properties, and interaction properties in the segment. Agents are assigned questions from the segment using Get Next if they have all of the skills required for the question." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label></div>
                                    <div class="list-container">
                                        <div class="checkbox" iservice-id-prefix="'Segment'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="5"> _iService</label></div>
                                        <div class="checkbox" iservice-id-prefix="'Segment'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="2"> 1to1service</label></div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group"><label class="control-label text-left"> Department Membership <span class="data-v-tooltip" data-v-tooltip="The agent will inherit any segment access or skills associated with their assigned department." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label></div>
                                    <div class="list-container">
                                        <div class="checkbox" iservice-id-prefix="'Department'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="3"> IT</label></div>
                                        <div class="checkbox" iservice-id-prefix="'Department'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="2"> Sales</label></div>
                                        <div class="checkbox" iservice-id-prefix="'Department'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="1"> Technical Support</label></div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group"><label class="control-label text-left"> Skills <span class="data-v-tooltip" data-v-tooltip="Skills are used to determine the questions that the agent can answer. The Get Next assignment process and agent notifications use these skills to determine the questions that are appropriate for the agent." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label></div>
                                    <div class="list-container">
                                        <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="17"> Build Tracking</label></div>
                                        <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="5"> critical</label></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12 mbottom-15"></div>
                            <div class="col-md-2"><button type="button" class="btn btn-primary margin-r-5">Save</button></div>
                            <div class="col-md-10 admin-error">
                                <!--v-if-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="tab-pane fade" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <div class="row">
                <div class="col-12 col-md-9">
                    <h3 style="text-align: right;" class="m-0">
                        <!--v-if--><span class="data-v-tooltip" data-v-tooltip="Logins and email addresses must be unique for every contact. If a login or email address exists within another contact, you'll receive an error when you try to add it to this contact. You can only set values for contact properties in segments to which you've been granted access." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 0%; --v-tooltip-top: 50%; --v-tooltip-translate: translate(-110%, -50%); --v-tooltip-arrow-border-color: transparent transparent transparent var(--v-tooltip-background-color); --v-tooltip-arrow-top: calc(var(--v-tooltip-top); --v-tooltip-arrow-left: calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) + 1.5px);"><i class="fa fa-question-circle text-info help-icon"></i></span>
                    </h3>
                </div>
                <div class="col-12 col-md-3">
                    <div style="display: none;">
                        <h3>Agent Settings <span class="data-v-tooltip" data-v-tooltip="Agent Settings control how agents interact with iService, such as whether they receive agent notifications. This section is designed for agents to control their own values. They can only be updated by other agents that have a higher level user type. If you do not have a higher level user type than the agent you are editing, you'll receive an Access Denied error." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 0%; --v-tooltip-top: 50%; --v-tooltip-translate: translate(-110%, -50%); --v-tooltip-arrow-border-color: transparent transparent transparent var(--v-tooltip-background-color); --v-tooltip-arrow-top: calc(var(--v-tooltip-top); --v-tooltip-arrow-left: calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) + 1.5px);"><i class="fa fa-question-circle text-info help-icon"></i></span></h3>
                    </div>
                    <h3>Segment Membership <span class="data-v-tooltip" data-v-tooltip="Segment membership determines the mailing list subscriptions available to the contact, and the agents that can view them. The default settings match yours by default. Make sure you set Segment Membership so the appropriate agents can view this contact." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 0%; --v-tooltip-top: 50%; --v-tooltip-translate: translate(-110%, -50%); --v-tooltip-arrow-border-color: transparent transparent transparent var(--v-tooltip-background-color); --v-tooltip-arrow-top: calc(var(--v-tooltip-top); --v-tooltip-arrow-left: calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) + 1.5px);"><i class="fa fa-question-circle text-info help-icon"></i></span></h3>
                    <div class="segment"><label class=""><input type="checkbox" class="form-check-input" value="5"> _iService</label></div>
                    <div class="segment"><label class=""><input type="checkbox" class="form-check-input" value="2"> 1to1service</label></div>
                </div>
                <div class="col-md-12"><button class="btn btn-primary me-1" type="button">Save</button><a href=""> Reset</a></div>
                <div class="col-md-12 mt-2">
                    <!--v-if-->
                </div>
                </div> 
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <AgentStockResponses></AgentStockResponses>
            </div>
         </div>
    </div>
</template>
<script>
import DashbordConfig from '../components/DashbordConfig.vue';
import ColorCodesConfig from '../components/ColorCodesConfig.vue';
import AgentStockResponses from '../components/AgentStockResponses.vue';
export default {
  name: 'Segment Level Settings',
  components: {
    DashbordConfig,
    ColorCodesConfig,
    AgentStockResponses
  },
  data: function(){
      return{
      }     
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>